
import { defineComponent, ref, onMounted, computed, onUpdated } from "vue";
import ExportCustomerModal from "@/components/modals/forms/ExportCustomerModal.vue";
import AddCompanyModal from "@/components/modals/forms/AddCompanyModal.vue";
import AddGroupModal from "@/components/modals/forms/AddGroupModal.vue";

import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import customers from "@/core/data/customers";
import roc_list, { business_types } from "@/core/data/genericData";
import { ICustomer } from "@/core/data/customers";
import axios from "axios";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";

export default defineComponent({
  name: "customers-listing",
  components: {
    ExportCustomerModal,
    AddGroupModal,
  },

  setup() {
    const count = ref(0);
    const checkedCompany = ref([]);
    const loadingData = ref<boolean>(true);

    interface WICompanies {
      group_id;
      group_name;
      parent_group_id;
      parent_group_name;
      group_nature;
      active: {
        label: string;
        color: string;
      };
    }

    var paginationData = ref({});

    paginationData.value = {
      start: 0,
      end: 0,
      total: 0,
      perPage: 5,
      activePage: 1,
      totPage: 1,
      mmaxPageg: 6,
    };

    const refreshData = () => {
      getUsers();
    };
    const changePageChange = (page) => {
      console.log(page);
      paginationData.value["activePage"] = page;
      getUsers();
    };

    var tableData = ref<Array<WICompanies>>([]);

    const getUsers = async () => {
      loadingData.value = true;

      try {
        var values = {
          // group_id: "",
          page: paginationData.value["activePage"],
          records_per_page: paginationData.value["perPage"],
        };
        await axios
          .post(
            "https://elogicalservices.mymetal.in/fa_common/group_list_page",
            values,
            {
              headers: {
                "x-api-key": "4HTvrHxuh98GvP3XWYXaUYMHgDnOuMa1aHXtDD95",
              },
            }
          )
          .then(({ data }) => {
            debugger;
            tableData.value = [];

            //pagination
            paginationData.value["total"] = data.data.total_records;
            var pg =
              paginationData.value["activePage"] *
              paginationData.value["perPage"];
            paginationData.value["start"] =
              pg - paginationData.value["perPage"];
            paginationData.value["end"] =
              paginationData.value["start"] +
              paginationData.value["perPage"] * 1;

            paginationData.value["totPage"] = Math.ceil(
              data.count / paginationData.value["perPage"]
            );

            paginationData.value["mmaxPageg"] =
              paginationData.value["totPage"] <
              paginationData.value["mmaxPageg"]
                ? paginationData.value["totPage"]
                : paginationData.value["mmaxPageg"];

            var resultsM = ref<Array<WICompanies>>([]);
            var status_label = "";
            var status_color = "";
            var active_label = "";
            var active_color = "";

            for (let j = 0; j < data.data.result_list.length; j++) {
              // if (data.data[j]["active"]) {
              //   active_label = "Yes";
              //   active_color = "success";
              // } else {
              //   active_label = "No";
              //   active_color = "danger";
              // }

              resultsM.value = Array({
                group_id: data.data.result_list[j]["group_id"],
                group_name: data.data.result_list[j]["group_name"],
                parent_group_id: data.data.result_list[j]["parent_group_id"],
                parent_group_name:
                  data.data.result_list[j]["parent_group"],
                group_nature: data.data.result_list[j]["group_nature_name"],
                active: {
                  label: active_label,
                  color: active_color,
                },
              });

              tableData.value.splice(
                j,
                resultsM.value.length,
                ...resultsM.value
              );
            }

            // tableData.value = [{
            //   group_id: 1,
            //   user: {
            //     avatar: "string;",
            //     name: "string;",
            //     group: "string;",
            //   },
            //   comp_type: "string;",
            //   status: {
            //     label: "string;",
            //     color: "string;",
            //   },
            //   active: {
            //     label: "string;",
            //     color: "string;,"
            //   },
            //   roc: "string,",
            //   nob: "string,",
            //   doi: "string,",
            //   pan: "string,"
            // }];

            // tableData = resultsM;
            loadingData.value = false;
          })
          .catch(({ response }) => {
            console.log(response);
          });
      } catch (e) {
        console.log(e);
      }
    };

    // function fnAPI(){

    //   companies_data = [
    //     {
    //       id: Math.floor(Math.random() * 99999) + 1,
    //       name: " (India) llp",
    //       email: "admin@metalxperts.com",
    //       company: "-",
    //       payment: {
    //         icon: "/media/svg/card-logos/visa.svg",
    //         ccnumber: "**** " + Math.floor(Math.random() * (9999 - 1000 + 1) + 1000),
    //         label: "visa",
    //       },
    //       date: "01 Dec 2020, 10:12 am",
    //     }
    //   ];

    //   console.log(companies_data);
    //   tableData = ref<Array<ICompanies>>(companies_data);
    //   initCustomers.value.splice(0, tableData.value.length, ...tableData.value);
    //   console.log(tableData);

    // }

    onUpdated(() => {
      // console.log("aaap");
      // console.log(list_data);
    });

    // var tableData = ref<Array<WICompanies>>([]);
    const initCustomers = ref<Array<WICompanies>>([]);

    onMounted(async () => {
      await getUsers();
      setCurrentPageBreadcrumbs("Groups", []);
      initCustomers.value.splice(0, tableData.value.length, ...tableData.value);
      console.log(tableData.value.length);
    });

    const deleteFewCustomers = () => {
      checkedCompany.value.forEach((item) => {
        deleteCustomer(item);
      });
      checkedCompany.value.length = 0;
    };

    const deleteCustomer = (id) => {
      for (let i = 0; i < tableData.value.length; i++) {
        if (tableData.value[i].group_id === id) {
          tableData.value.splice(i, 1);
        }
      }
    };

    const search = ref<string>("");
    const searchItems = () => {
      tableData.value.splice(0, tableData.value.length, ...initCustomers.value);
      if (search.value !== "") {
        let results: Array<WICompanies> = [];
        for (let j = 0; j < tableData.value.length; j++) {
          if (searchingFunc(tableData.value[j], search.value)) {
            results.push(tableData.value[j]);
          }
        }
        tableData.value.splice(0, tableData.value.length, ...results);
      }
    };

    const searchingFunc = (obj, value): boolean => {
      for (let key in obj) {
        if (!Number.isInteger(obj[key]) && !(typeof obj[key] === "object")) {
          if (obj[key].indexOf(value) != -1) {
            return true;
          }
        }
      }
      return false;
    };

    return {
      tableData,
      deleteCustomer,
      search,
      searchItems,
      checkedCompany,
      deleteFewCustomers,
      count,
      getUsers,
      paginationData,
      changePageChange,
      refreshData,
      loadingData,
    };
  },
});
